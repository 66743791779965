// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "input" ]
    greet(e) {
        fetch(`/test?page=1`, { headers: { accept: "application/json" } })
        .then(response => response.json())
        .then((data) => {

          const html = data.map(ele => `<li>${ele.name}</li>`).join("")
          this.nameTarget.innerHTML = html
      })
    }

    search(e) {
      const value = e.target.value

      fetch(`/test?q%5Bname_or_description_cont%5D=${value}`, { headers: { accept: "application/json" } })
      .then(response => response.json())
      .then((data) => {
        const html = data.map(ele => `<li>${ele.name}</li>`).join("")
        this.nameTarget.innerHTML = ""
        this.nameTarget.innerHTML = html
    })
  }
}
