// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element,{
      group:{
        name: 'shared',
      },
      handle:".drag-calendar",
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }
  remove(data){
    let calendarID = data.target.dataset.calendar
    let calendarRecipeID = data.target.dataset.calendarrecipe
    let uniqueRecipeID = data.target.dataset.uniqueid
    let elementRecipe = document.getElementById(calendarRecipeID)
    const element = document.getElementById("ajax_delete");
    console.log("removeee")
    Rails.ajax({
      url: `/calendarios/${calendarID}/removeRecipeCalendar/${uniqueRecipeID}`,
      type: 'GET',
      success: function(response){
        const parentElement = data.target.closest(".card-edit-calendar")
        parentElement.parentNode.removeChild(parentElement);
        element.classList.remove("d-none")
        setTimeout(function() {
          element.classList.add("d-none")
        }, 2000);
      },
      error: function(err){
        element.classList.remove("d-none")
        setTimeout(function() {
          element.classList.add("d-none")
        }, 2000);
      }
    })    
  }

  addrecipe(data){
    let calendarID = data.target.dataset.calendar
    let recipeCalendarID = data.target.dataset.uniqueid
    console.log("click add")
    Rails.ajax({
      url: `/calendarios/${calendarID}/addRecipeCalendar/${recipeCalendarID}`,
      type: 'POST',
      success: function(response){
      },
      error: function(err){
        const element = document.getElementById("ajax_error");
        element.classList.remove("d-none")
        setTimeout(function() {
          element.classList.add("d-none")
        }, 2000);
      }
    })
  }

  end(e){
    let calendarID = e.to.dataset.calendar
    let recipeCalendarID = e.item.dataset.calendarrecipeid // ID Receta
    // let uniqueRecipeID = data.target.dataset.uniqueid
    let calendarDay = e.to.dataset.day
    let data = new FormData()
    data.append('day', calendarDay)

    Rails.ajax({
      url: `/calendarios/${calendarID}/recipeCalendarUpdate/${recipeCalendarID}`,
      type: 'PATCH',
      data:data,
      success: function(response){
        console.log("holaa")
        const element = document.getElementById("ajax_success");
        element.classList.remove("d-none")
        setTimeout(function() {
          element.classList.add("d-none")
        }, 2000);
      },
      error: function(err){
        const element = document.getElementById("ajax_error");
        element.classList.remove("d-none")
        setTimeout(function() {
          element.classList.add("d-none")
        }, 2000);
      }
    })
  }
}
