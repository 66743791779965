$(window).on('click', function (e) {

    if(e.target.getAttribute("data-id") == "icon_close"){
        e.preventDefault();
        $("#filter").removeClass("responsive-show");
        $(".filter_type #filter_2").removeClass("show")
    }
});

$(window).on('click', function (e) {
    const element = document.querySelector(".responsive-filter")
    if(e.target == element){
        e.preventDefault();
        $("#filter").addClass("responsive-show");
    }
});

$(window).on('click', function (e) {
   const id = e.target.getAttribute("data-id")
   const element = document.body
   const printElement = document.getElementById(id)


   if (e.target.getAttribute("data-selector") == "export"){
    var opt = {
        margin:       1,
        filename:     'lista_supermercado.pdf',
        image:        { type: 'jpeg', quality: 0.98 },
        html2canvas:  { width: 800 },
      };
        html2pdf().set(opt).from(printElement).save()
   }
});
