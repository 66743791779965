// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { Application } from "@hotwired/stimulus"

window.Stimulus = Application.start()

require("jquery")



Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("@nathanvda/cocoon")


require("@popperjs/core")

import "bootstrap"

// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"
import "trix"
import "@rails/actiontext"
import "@fortawesome/fontawesome-free/css/all.css";

import './ingredient';

// The stylesheet location we created earlier
require("../stylesheets/main.scss")

// If you're using Turbolinks. Otherwise simply use: jQuery(function () {
document.addEventListener("turbolinks:load", () => {
    // Both of these are from the Bootstrap 5 docs
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
    })

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
        return new Popover(popoverTriggerEl)
    })
    jQuery(window).on('load', function () {
        require('packs/jquery.magnific-popup');
        require('packs/common_scripts.min');
        require('packs/common_func');
        require('packs/validate');
        require('packs/sticky_sidebar');
        require('packs/specific_listing');
        require('packs/custom')
    });
})





// import the bootstrap javascript module
// import "bootstrap"


require.context('../images', true)


import "controllers"


Stimulus.handleError = (error, message, detail) => {
  console.warn(message, detail)
  ErrorTrackingSystem.captureException(error)
}
