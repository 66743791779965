// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import Rails from "@rails/ujs";

const NMI_RESPONSE_CODES = {
  APPROVED: "100",
  DECLINED_BY_PROCESSOR: "200",
  DO_NOT_HONOR: "201",
  INSUFFICIENT_FUNDS: "202",
  OVER_LIMIT: "203",
  TRANSACTION_NOT_ALLOWED: "204",
  INCORRECT_PAYMENT_INFORMATION: "220",
  NO_SUCH_CARD_ISSUER: "221",
  NO_CARD_NUMBER_ON_FILE_WITH_ISSUER: "222",
  EXPIRED_CARD: "223",
  INVALID_EXPIRATION_DATE: "224",
  INVALID_CARD_SECURITY_CODE: "225",
  INVALID_PIN: "226",
  CALL_ISSUER_FOR_FURTHER_INFORMATION: "240",
  PICK_UP_CARD: "250",
  LOST_CARD: "251",
  STOLEN_CARD: "252",
  FRAUDULENT_CARD: "253",
  DECLINED_WITH_FURTHER_INSTRUCTIONS_AVAILABLE: "260",
  DECLINED_STOP_ALL_RECURRING_PAYMENTS: "261",
  DECLINED_STOP_THIS_RECURRING_PROGRAM: "262",
  DECLINED_UPDATE_CARDHOLDER_DATA_AVAILABLE: "263",
  DECLINED_RETRY_IN_A_FEW_DAYS: "264",
  TRANSACTION_WAS_REJECTED_BY_GATEWAY: "300",
  TRANSACTION_ERROR_RETURNED_BY_PROCESSOR: "400",
  INVALID_MERCHANT_CONFIGURATION: "410",
  MERCHANT_ACCOUNT_IS_INACTIVE: "411",
  COMMUNICATION_ERROR: "420",
  COMMUNICATION_ERROR_WITH_ISSUER: "421",
  DUPLICATE_TRANSACTION_AT_PROCESSOR: "430",
  PROCESSOR_FORMAT_ERROR: "440",
  INVALID_TRANSACTION_INFORMATION: "441",
  PROCESSOR_FEATURE_NOT_AVAILABLE: "460",
  UNSUPPORTED_CARD_TYPE: "461",
}

const gatewayUrl = "https://y58974jge8.execute-api.us-east-1.amazonaws.com/dev/"

export default class extends Controller {
  static targets = ["error"]

  queryString2json(qs) {
    //Convierte el Query string de respuesta NMI a JSON.
    qs = qs || location.search.slice(1);
    var pairs = qs.split('&');
    var result = {};
    pairs.forEach(function (p) {
      var pair = p.split('=');
      var key = pair[0];
      var value = decodeURIComponent(pair[1] || '');
      if (result[key]) {
        if (Object.prototype.toString.call(result[key]) === '[object Array]') {
          result[key].push(value);
        } else {
          result[key] = [result[key], value];
        }
      } else {
        result[key] = value;
      }
    });
    return JSON.parse(JSON.stringify(result));
  }
  request(e) {
    e.preventDefault()
    const plans = {
      1: "julaimames",
      2: "julaimatrimestre",
      3: "julaimaanual"
    }
    e.preventDefault()
    const form = e.target
    const { name, last_name, card_number, expire_month, expire_year, ccv, plan, email } = form
    const button = e.target.querySelector('input[type="submit"]')
    button.disabled = true
    let yourDate = new Date()
    yourDate.setDate(yourDate.getDate() + 3)
    let tomorrow = yourDate.toISOString().split('T')[0]
    tomorrow = tomorrow.split("-").join("")
    const validate = {
      type: "validate",
      first_name: name.value,
      last_name: last_name.value,
      ccnumber: card_number.value,
      ccexp: `${expire_month.value}-${expire_year.value}`,
      ccv: ccv.value,
      email: email.value,
      customer_vault: "add_customer",
    }

    var recurring = {
      plan_id: plans[plan.value],
      start_date: tomorrow,
      recurring: "add_subscription",
      customer_vault_id:"",
    }

    fetch(gatewayUrl, {
      method: "POST",
      body: JSON.stringify(validate)
    }).then(response => response.json())
      .then(data => {
        const request = this.queryString2json(data.body)

        if (request.response_code == "100") {

            recurring.customer_vault_id = request.customer_vault_id
            fetch(gatewayUrl, {
              method: "POST",
              body: JSON.stringify(recurring)
            }).then(subscription => subscription.json())
              .then(subscriptionData => {
                const requestSuscription = this.queryString2json(subscriptionData.body)
  
                if(requestSuscription.response_code =="100"){
                  const element = document.getElementById("ajax_delete");
                  let data = new FormData()
                  data.append('plan_id', plan.value)
                  data.append("subscription_id", requestSuscription.subscription_id)
                  Rails.ajax({
                    url: `/plans/${plan.value}/subscriptions`,
                    type: 'POST',
                    data: data,
                    success: function (response) {
                      button.disabled = false
                      element.classList.remove("d-none")
                      setTimeout(function () {
                        element.classList.add("d-none")
                      }, 2000);
                    },
                    error: function (err) {
                      element.classList.remove("d-none")
                      button.disabled = false
                      setTimeout(function () {
                        element.classList.add("d-none")
                      }, 2000);
                    }
                  })
                }
                else {
                  const errorElement = this.errorTarget
                  errorElement.innerHTML = ""
                  errorElement.innerHTML = requestSuscription.responsetext
                  button.disabled = false

                }
  
              })

     
        }
        else {
          const element = this.errorTarget
          element.innerHTML = ""
          element.innerHTML = request.responsetext
          button.disabled = false

        }
      })
  }

  delete(e) {
    const buttonElement = e.target;
    buttonElement.setAttribute("disabled", true);
    buttonElement.style.cursor = "not-allowed";
    buttonElement.style.opacity = "0.6";

    const plan = e.target.getAttribute("plan_id");
    const subscription_id = e.target.getAttribute("subscription_id");
    const id = e.target.getAttribute("gateway_id");

    const body = {
      first_name: null,
      last_name: null,
      ccnumber: null,
      ccexp: null,
      plan_id: null,
      ccv: null,
      subscription_id: id,
      recurring: "delete_subscription"
    };

    fetch(gatewayUrl, {
      method: "POST",
      body: JSON.stringify(body)
    }).then(response => response.json())
      .then(data => {
        const response = this.queryString2json(data.body)
        if (
          response.response_code == NMI_RESPONSE_CODES.APPROVED ||
          (response.response_code === NMI_RESPONSE_CODES.TRANSACTION_WAS_REJECTED_BY_GATEWAY && response.responsetext.includes('No recurring subscriptions found'))
        ) {
          Rails.ajax({
            url: `/plans/${plan}/subscriptions/${subscription_id}`,
            type: 'DELETE',
            success: function (response) {
              const notificationElement = document.getElementById("ajax_delete");
              notificationElement.classList.add("alert-success")
              notificationElement.innerHTML = ""
              notificationElement.innerHTML = 'Suscripción eliminada correctamente';
              notificationElement.classList.remove("d-none");
              setTimeout(function () {
                buttonElement.removeAttribute("disabled");
                buttonElement.style.cursor = "";
                buttonElement.style.opacity = "";

                notificationElement.classList.add("d-none")
                notificationElement.classList.remove("alert-success")
              }, 2000);
            },
            error: function (err) {
              const notificationElement = document.getElementById("ajax_delete");
              notificationElement.classList.add("alert-danger")
              notificationElement.innerHTML = ""
              notificationElement.innerHTML = 'Error al eliminar la suscripción'
              notificationElement.classList.remove("d-none");
              setTimeout(function () {
                buttonElement.removeAttribute("disabled");
                buttonElement.style.cursor = "";
                buttonElement.style.opacity = "";

                notificationElement.classList.add("d-none");
                notificationElement.classList.remove("alert-danger")
              }, 2000);
            }
          })
        }
        else {
          const notificationElement = document.getElementById("ajax_delete");
          notificationElement.classList.add("alert-danger")
          notificationElement.innerHTML = ""
          notificationElement.innerHTML = "Error al eliminar la suscripción";
          notificationElement.classList.remove("d-none");

          setTimeout(function () {
            notificationElement.classList.add("d-none");
            notificationElement.classList.remove("alert-danger")
          }, 2000);
        }
      }
    )
  }
}
