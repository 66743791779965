"use strict";


    // Carousel categories
    var owlcat_in = $('.categories_carousel_in');
    owlcat_in.owlCarousel({
        center: false,
        stagePadding: 50,
        items: 1,
        loop: false,
        margin: 20,
        dots: false,
        nav: true,
        lazyLoad: true,
        navText: ["<i class='arrow_left'></i>","<i class='arrow_right'></i>"],
        responsive: {
            0: {
                nav: false,
                dots: false,
                items: 2
            },
            600: {
                nav: false,
                dots: false,
                items: 2
            },
            1025: {
                nav: true,
                dots: false,
                items: 3
            },
            1280: {
                nav: true,
                dots: false,
                items: 4
            },
            1440: {
                nav: true,
                dots: false,
                items: 5
            }
        }
    });
    
    // Sticky sidebar
    // $('#sidebar_fixed').theiaStickySidebar({
    //     minWidth: 991,
    //     updateSidebarHeight: true,
    //     additionalMarginTop: 25
    // });

    // Button show/hide map
    $(".btn_map").on("click", function () {
        var el = $(".btn_map_txt");
        el.text() == el.data("text-swap") ? el.text(el.data("text-original")) : el.text(el.data("text-swap"));
        $('html, body').animate({
            scrollTop: $("body").offset().top
        }, 600);
    });

    // Range slider
    // $('input[type="range"]').rangeslider({
    //     polyfill: false,
    //     onInit: function () {
    //         this.output = $(".distance span").html(this.$element.val());
    //     },
    //     onSlide: function (
    //         position, value) {
    //         this.output.html(value);
    //     }
    // });

    //Filters collapse
    var $headingFilters = $('.filter_type h4 a');
    $headingFilters.on('click', function () {
        $(this).toggleClass('opened');
    })
    $headingFilters.on('click', function () {
        $(this).toggleClass('closed');
    });

    //Filters on mobile
    $('a.open_filters').on("click", function () {
        $('.filter_col').toggleClass('show');
        $('.layer').toggleClass('layer-is-visible');
    });